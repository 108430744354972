import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin-panel-nav-menu" }
const _hoisted_2 = { class: "admin-panel-nav-menu__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/admin/analytics/skills-tracking",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Analytics")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/instructors",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Instructors")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/students",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Students")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/skill-achievement-log",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Skills log")
        ])),
        _: 1
      }),
      (this.isFinancialManager === 'true' && this.isSubscriptionEnabled === 'true')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/admin/payments-log",
            class: "admin-panel-nav-menu__menu-item"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Payments Log")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "/admin/stickers",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Stickers")
        ])),
        _: 1
      }),
      (this.isFinancialManager === 'true' && this.isSubscriptionEnabled === 'true')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/admin/billing-activity",
            class: "admin-panel-nav-menu__menu-item"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Billing activity")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "/admin/students-history",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("Students' history")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/settings",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Settings")
        ])),
        _: 1
      })
    ])
  ]))
}