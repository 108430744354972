
import { defineComponent } from "vue";
import CloseIcon from "@/assets/svg/close-icon.svg?inline";

export default defineComponent({
  name: "DefaultPopup",
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
    message: {
      type: String,
    },
  },
  components: {
    CloseIcon,
  },
  methods: {
    handleCloseModal() {
      this.onClose();
    },
  },
});
