
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdminPanelNavMenu",
  data() {
    return {
      isFinancialManager: localStorage.getItem("financial-manager"),
      isSubscriptionEnabled: localStorage.getItem("is-subscription-enabled"),
    };
  },
  components: {},
  methods: {},
});
